import * as React from "react";
import styled from "styled-components";
import Simple from "@templates/Simple";
import { Link } from "gatsby";

const Page = () => {
  return (
    <Simple title={"CRI: Certificación"}>
      <Wrapper>
        <h1>Certificación</h1>
        <p>
          Si usted desea realizar la evaluación para incoporarlo a su perfil del{" "}
          <b>CTI Vitae - CONCYTEC</b>, por favor haga clic{" "}
          <a href={"http://54.157.173.61/"} target={"_blank"}>
            AQUÍ
          </a>
          <br />
          <br />
          Notas:
          <ul>
            <li>
              Debe de ingresar con su usuario y clave del CTI Vitae, mas
              información{" "}
              <a
                href={
                  "https://sites.google.com/a/concytec.gob.pe/manual-dina/cri"
                }
                target={"_blank"}
              >
                {" "}
                aquí
              </a>
              .
            </li>
            <li>
              <b>
                Las notas no se comparten enttre los sistemas de Concytec y de
                Andean Quipu, por lo cual deberá registrarse y rendir la misma evaluación con
                las mismas preguntas de nuevo para obtener esta certificación.
              </b>
            </li>
          </ul>
          <br />
          <br />
          Si usted desea obtener su certificado por Andean Quipu de la{" "}
          <b>Universidad Peruana Cayetano Heredia</b> por favor de clic{" "}
          <a href={"https://cri.andeanquipu.org/moodle/"} target={"_blank"}>
            AQUÍ
          </a>
          <br />
          <br />
          Notas:
          <ul>
            <li>
              Instruciones para ingresar al Moodle de Andean Quipu,{" "}
              <Link to={"/informacion"}> aquí </Link>.
            </li>
            <li>
              <b>
                Las notas no se comparten entre los sistemas de Concytec y de
                Andean Quipu, por lo cual deberá registrarse y rendir la misma evaluación con
                las mismas preguntas de nuevo para obtener esta certificación.
              </b>
            </li>
          </ul>
        </p>
      </Wrapper>
    </Simple>
  );
};

export default Page;

const Wrapper = styled.div`
  padding: 1em 2em;
  margin-bottom: 5em;

  color: var(--color4);
  h1 {
    font-size: 29px;
    padding: 15px 0;
  }
  p {
    font-size: 16px;
    padding: 5px 0;
  }
  p {
    a {
      color: var(--color1);
      font-size: 20px;
    }
  }
`;
